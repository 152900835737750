@import "../variables";

.modal-container {
  .modal-content {
    height: calc(100vh - 60px);
    overflow-y: auto;

    label.section-header {
      color: #101828;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 9px;
    }

    .modal-header {
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      height: 52px;
      padding: 0 5rem;
      border-bottom: none !important;
      margin-bottom: 17px;

      .modal-title {
        color: #101828;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .header-btns {
        display: flex;
        gap: 1rem;
        align-items: center;

        svg {
          cursor: pointer;
        }

        .close {
          border: none;
          font-size: 2rem;
          background-color: transparent;
        }

        .edit-btn {
          background-color: #1d71d3;
          height: 2rem;
          color: white;
          padding: 0rem 2rem 0rem 2rem;
        }
      }
    }

    .modal-body {
      input[type="color"] {
        margin-block-end: 1rem;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: none;
        border: 0;
        cursor: pointer;
        height: 3rem;
        padding: 0;
        width: 3rem;
      }

      padding: 0 5rem;
      overflow-y: auto;

      .view-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .info-details-wrapper,
        .info-pic-wrapper {
          display: flex;
          width: 85%;
          flex-wrap: wrap;

          .info-item {
            display: flex;
            width: 30%;

            .label {
              color: #98A2B3;
              font-family: Lato;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-inline-end: 6px;
            }

            .value {
              color: #475467;
              font-family: Lato;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .info-mat {
            display: flex;
            width: 45%;
          }
        }

        .info-pic-wrapper {
          align-items: baseline;
          margin-bottom: 32px;
          width: 100%;
          justify-content: space-between;

          .pic-name {
            display: flex;
            align-items: baseline;
            width: 80%;
          }
        }

        .edit-btn-wrapper {
          width: 15%;
          padding: 0;
          display: flex;
          justify-content: end;

          .edit-btn {
            display: flex;
            // width: 151px;
            height: 42px;
            padding: 5px 18px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 30px;
            background: #0074EC;
            color: #FFF;
            text-align: center;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.084px;
            width: fit-content;
            min-width: 100%;
          }
        }
      }

      .img-section {
        display: flex;
        flex-direction: column;
        // margin-bottom: 2rem;

        .modal-no-image {
          background-color: #f2f2f2;
          padding: 1.4rem 1.5rem 1rem 1.7rem;
          width: 6rem;
          height: 6rem;
          border-radius: 50%;
          margin-inline-end: 24px;

          #camera {
            margin-top: 0.5rem;
            margin-left: 2rem;
          }
        }

        .modal-image {
          background-color: $gray-100;
          // padding: 1.5rem;
          position: relative;
          width: fit-content;
          border-radius: 50%;
          margin-inline-end: 24px;

          img {
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
          }

          // .driver-image {
          //   width: 6.25rem;
          //   height: 6.25rem;
          //   border-radius: 50%;
          // }

          #camera {
            position: absolute;
            top: -2rem;
            // left: 14rem;
          }
        }
      }

      .trips {
        margin-bottom: 2rem;

        &-content {
          gap: 2rem;
          justify-content: space-between;
          margin-top: 1rem;
          padding-inline-start: 1rem;

          .trip-item {
            display: flex;
            justify-content: space-between;
            gap: 0.5rem;
            padding: 12px 16px;
            border-radius: 6px;
            border: 1px solid #F8F8F8;
            background: #FFF;
            box-shadow: 0px 26px 7px 0px rgba(214, 214, 214, 0.00), 0px 17px 7px 0px rgba(214, 214, 214, 0.01), 0px 9px 6px 0px rgba(214, 214, 214, 0.05), 0px 4px 4px 0px rgba(214, 214, 214, 0.09), 0px 1px 2px 0px rgba(214, 214, 214, 0.10);

            .name {
              color: #475467;
              font-family: Lato;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .from-to {
              color: #667085;
              font-family: Lato;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .time {
              color: #0074EC;
              font-family: Lato;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }

      .map-container {
        .search-map-section {
          margin-bottom: -.5rem;
        }

        .map-section {
          width: inherit;
          height: inherit;
          position: relative;

          .currentLocation {
            width: 2.5rem;
            background-color: #ffff;
            border: 2px solid #ffff;
            border-radius: 3px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
            color: rgb(25, 25, 25);
            cursor: pointer;
            font-family: Roboto, Arial, sans-serif;
            font-size: 16px;
            line-height: 38px;
            margin: 8px 0 22px;
            padding: 0 5px;
            text-align: center;
            position: absolute;
            right: 10px;
            top: 50px;
          }
        }

        .map-address-section {
          width: 100%;
          margin-top: 16px;
          margin-bottom: 24px;
          .address {
            color: #667085;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    .modal-footer {
      justify-content: center;
      border: unset;
      margin-bottom: 0.34375em;
      padding: 0 4.6rem !important;

      .btns-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .cancel-btn {
          display: flex;
          width: 49%;
          height: 42px;
          padding: 5px 18px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 24px;
          border: 1px solid #667085;
          color: #667085;
          text-align: center;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.084px;
          background: transparent;
        }

        .save-btn {
          display: flex;
          width: 49%;
          height: 42px;
          padding: 5px 18px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 21px;
          background: #0074EC;
          color: #FFF;
          text-align: center;
          font-family: Lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.084px;
        }
      }

      &.none {
        display: none;
      }
    }
  }

  ::ng-deep .mat-select-panel {
    background-color: $white;
    position: relative;
    top: 3.5rem !important;

    .mat-option-text {
      color: $primary-font-color;
    }

    mat-option:hover {
      background-color: $gray-200 !important;
    }
  }

  // disabled select inputs

  ::ng-deep.mat-form-field-disabled {
    .mat-form-field-outline-thick {
      color: $primary-font-color !important;
    }

    .mat-form-field-label {
      color: $primary-font-color !important;
    }

    .mat-select-arrow {
      border: unset !important;
    }
  }

  ::ng-deep .mat-select-arrow-wrapper {
    transform: translateY(15%) !important;
  }

  ::ng-deep .mat-select-disabled {
    .mat-select-value {
      color: $primary-font-color !important;
    }
  }

  // end of disabled part

  ::ng-deep .mat-form-field-flex>.mat-form-field-infix {
    padding: 0.4rem !important;
    border-bottom: 0.84375em solid transparent !important;
  }

  ::ng-deep .mat-form-field-label-wrapper {
    top: -1rem;
  }

  ::ng-deep .mat-select-arrow-wrapper {
    transform: translateY(15%) !important;
  }

  ::ng-deep .mat-form-field-subscript-wrapper {
    margin-top: 0.1rem !important;
  }

  ::ng-deep .mat-form-field {
    width: 100%;
    font-family: $font-family;

    .mat-form-field-label {
      color: $primary-font-color;
    }

    .mat-form-field-outline-thick {
      color: $primary-font-color;
      opacity: unset !important;

      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border-width: 1px !important;
      }
    }

    .mat-input-element {
      text-transform: none;
    }

    .mat-form-field-underline {
      background-color: $red;
    }

    .mat-form-field-ripple {
      background-color: $red;
    }

    .mat-form-field-required-marker {
      color: $red;
    }
  }

  ::ng-deep .mat-form-field.mat-focused {
    .mat-form-field-outline-thick {
      color: $blue-color;

      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border-width: 2px !important;
      }
    }

    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0.5em 0em 1em 0em;
    }

    .mat-form-field-label {
      color: $blue-color;
    }

    .mat-form-field-ripple {
      background-color: #ff884d;
    }

    .mat-form-field-required-marker {
      color: #ff884d;
    }

    .mat-input-element {
      color: $primary-font-color;
      text-transform: none;
    }
  }

  ::ng-deep .mat-select-value {
    color: $primary-font-color;
  }

  ::ng-deep .mat-select-arrow {
    color: $primary-font-color !important;
  }

  @media (min-width: 2560px) {
    .modal-content {
      .search-img {
        margin-inline-start: 1rem !important;
      }
    }
  }
}

.rtl {
  ::ng-deep .mat-form-field-appearance-outline {
    .mat-form-field-outline-start {
      border-right-style: solid;
      border-left-style: solid;
      border-right-style: none;
      border-radius: 5px 0 0 5px;
    }

    .mat-form-field-outline-end {
      border-right-style: solid;
      border-left-style: none;
      border-radius: 0px 5px 5px 0px;
    }
  }

  ::ng-deep .mat-select-trigger {
    direction: rtl;
  }

  ::ng-deep .mat-form-field-should-float {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-outline {
          .mat-form-field-outline-end {
            border-top-style: none;
          }
        }
      }
    }
  }
}