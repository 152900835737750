$font-family: "lato";

$primary-bg-color: #fafafa;
$blue-color: #1d71d3;
$white: white;
$med-color: rgba(238, 238, 238, 1);
$primary-font-color: #707070;

// $gray-50: #F9FAFB;
 $gray-100: #f2f2f2;
$gray-200: rgb(173, 171, 171);
$gray-300: #b5b5b5;
$gray-500: #797979;
// $gray-700: #344054;
// $gray-800: #1D2939;
// $gray-900: #101828;

// Errors

$red: red;
