/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "bootstrap/scss/bootstrap";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./components/modal";


@page {
  size: A4;
  margin: 0;
}

* {
  padding: 0;
  margin: 0;
  transition: 1s display ease-in-out;
  text-transform: capitalize;
}

.cdk-global-scrollblock {
  position: initial;
  width: initial;
  overflow: hidden;
}

html,
body {
  height: 100%;

  .cdk-overlay-container {
    z-index: 1000000;
  }
}

body {
  font-family: "lato", sans-serif !important;
  overflow-x: hidden;
}

button:focus,
button:active:focus,
button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

//   tooltip styling
.my-custom-class {
  .arrow {
    display: none;
  }

  .tooltip-inner {
    background-color: unset;
  }
}

.my-custom-grades-class {
  left: 20rem;

  .arrow {
    display: none;
  }

  .tooltip-inner {
    background-color: unset;
    position: relative;
    left: 21rem;
  }
}

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-thumb {
    background-color: white;
  }

  .mat-slide-toggle-bar {
    background-color: #1d71d3;
  }
}

// checkbox design
.mat-checkbox-inner-container {
  top: 4px;
}

.mat-checkbox-background {
  background-color: white;
  border: 1px solid #d6d6d6;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: white;
  border: 1px solid #d6d6d6;

  svg {
    path {
      stroke: #707070 !important;
    }
  }
}

.mat-snack-bar-container {
  padding: unset !important;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.danger {
  font-size: 75% !important;
  color: #f44336 !important;
  margin-inline-start: 0.75rem;
}

.noResultsFound {
  margin: 0;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.noResultsFoundAdjusted {
  margin: 0;
  position: absolute;
  top: 60%;
  // left: 60%;
  transform: translate(-50%, -50%);
}

// to fix map search dropdown not visible inside of modal popups
div.pac-container {
  z-index: 99999999999 !important;
}

html {
  --mat-option-selected-state-label-text-color: #1d71d3;
  --mat-option-label-text-color: #707055;
  // --mat-option-hover-state-layer-color: #ADABAB;
  // --mat-option-focus-state-layer-color: #ADABAB;
  // --mat-option-selected-state-layer-color: #ADABAB;
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: .875rem;
  --mat-option-label-text-tracking: .03125em;
  --mat-option-label-text-weight: 400;
  --mat-select-enabled-trigger-text-color: #707055;
  --mat-select-trigger-text-font: Roboto, Helvetica Neue, sans-serif;
  // --mat-select-trigger-text-line-height:0px;
  --mat-select-trigger-text-size: .875rem;
  --mat-select-trigger-text-weight: 400;
  --mat-select-trigger-text-tracking: .03125em;
  --mdc-filled-text-field-caret-color: #1D71D3;
  --mdc-filled-text-field-focus-active-indicator-color: #1D71D3;
  --mdc-filled-text-field-focus-label-text-color: #1D71D3;
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #FAFAFA;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, .6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, .38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, .87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, .38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, .6);
  --mdc-filled-text-field-error-focus-label-text-color: #F44336;
  --mdc-filled-text-field-error-label-text-color: #F44336;
  --mdc-filled-text-field-error-caret-color: #F44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, .42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, .06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, .87);
  --mdc-filled-text-field-error-active-indicator-color: #F44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #F44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #F44336;
  --mdc-outlined-text-field-caret-color: #1D71D3;
  --mdc-outlined-text-field-focus-outline-color: #1D71D3;
  --mdc-outlined-text-field-focus-label-text-color: #1D71D3;
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, .6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, .38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, .87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, .38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, .6);
  --mdc-outlined-text-field-error-caret-color: #F44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #F44336;
  --mdc-outlined-text-field-error-label-text-color: #F44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, .38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, .06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, .87);
  --mdc-outlined-text-field-error-focus-outline-color: #F44336;
  --mdc-outlined-text-field-error-hover-outline-color: #F44336;
  --mdc-outlined-text-field-error-outline-color: #F44336;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, .38);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1D71D3;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: #72B0F0;
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: #72B0F0;
  --mat-datepicker-calendar-date-hover-state-background-color: #72B0F0;
  --mat-datepicker-toggle-active-state-icon-color: #1D71D3;
  --mat-datepicker-calendar-date-in-range-state-background-color: #72B0F0;
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, .2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #A8DAB5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46A35E;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, .54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, .54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, .54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, .54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, .12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, .54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, .38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, .18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, .87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, .38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, .24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, .87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, .38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, .38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, .87);
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1D71D3;
  --mdc-checkbox-selected-hover-icon-color: #1D71D3;
  --mdc-checkbox-selected-icon-color: #1D71D3;
  --mdc-checkbox-selected-pressed-icon-color: #1D71D3;
  --mdc-checkbox-unselected-focus-icon-color: #D8D8D8;
  --mdc-checkbox-unselected-hover-icon-color: #D8D8D8;
  --mdc-checkbox-unselected-icon-color: #D8D8D8;
  --mdc-checkbox-unselected-pressed-icon-color: #D8D8D8;
  --mdc-checkbox-selected-focus-state-layer-color: #1D71D3;
  --mdc-checkbox-selected-hover-state-layer-color: #1D71D3;
  --mdc-checkbox-selected-pressed-state-layer-color: #1D71D3;
  --mdc-checkbox-unselected-focus-state-layer-color: #F3F3F3;
  --mdc-checkbox-unselected-hover-state-layer-color: #F3F3F3;
  --mdc-checkbox-unselected-pressed-state-layer-color: #F3F3F3;
}

.mat-mdc-form-field {
  width: 100%;
}

html[dir=ltr] {
  .mat-mdc-form-field {
    direction: ltr;
  }
}

html[dir=rtl] {
  .mat-mdc-form-field {
    direction: rtl;
  }
}

.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block !important;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: white;
}

.mat-mdc-menu-panel {
  --mat-menu-container-shape: 10px !important;
  height: fit-content;
}

.mat-mdc-menu-item {
  min-height: 39px !important;

  .mat-mdc-menu-item-text {
    img {
      margin-inline-end: 10px !important;
    }
  }
}

// scrollbar styling
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ACD1F8;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ACD1F8;
  cursor: pointer;
}

.custom-map-control-button {
  background: none padding-box rgb(255, 255, 255);
  display: table-cell;
  border: 0px;
  margin: 0px;
  padding: 10px 17px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  color: rgb(86, 86, 86);
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 36px;
}

.custom-map-control-button:hover {
  background-color: #ebebeb;
}

.custom-map-control-button.active {

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  color: rgb(0, 0, 0);
  font-weight: 500;
}

.custom-map-control-button:active {
  font-weight: 500;
  color: rgb(0, 0, 0);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}